.resume-container {
    background-color: #293038;
    color: white;
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resume {
    display: flex;
    width: 70%;
    height: 100%;
    padding: 2rem 1rem;
}