.navigation-container {
    display: flex;
    /* margin: 1rem 0 0 0; */
    /* background-color: #293038; */
    /* padding-top: 1rem; */
    /* padding-bottom: 0.5rem; */
    height: 10vh;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    align-items: center;
    position: relative;
}

.navigation-left-header {
    display: flex;
    flex: 3.5;
    font-size: 1.8rem !important;
    margin-left: 3%;
    height: 10vh;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;

}

.navigation-left-header:hover {
    text-decoration: none !important;
}

.navigation-logo {
    margin-top: 5%;
}

.navigation-left-links {
    display: flex;
    flex: 12;
    /* margin-left: 5%; */
}

.navigation-right-links {
    flex: 2;
    justify-content: center;
}

.navigation-main-link {
    margin-right: 3rem;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    margin-left: 5%;
    /* line-height: 30px!important; */


}

.navigation-link {
    text-decoration: none;
    color: white;
    font-size: 1.15rem;
    /* padding: 1rem 0.25rem; */
    cursor: pointer;

}

.navigation-link:hover {
    /* background-color: grey; */
    text-decoration: underline;
    /* color: red; */
}

.navigation-link:active {
    text-decoration: none;
    /* color: rgb(35, 182, 35); */
}

.contact-link {
    background-color: transparent;
    border: 2px solid rgb(9, 160, 206);
    width: 100px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 8px; */
    cursor: pointer;
    color: white;
    height: 2.5rem;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.contact-link:hover {
    background-color: rgb(9, 160, 206);
    text-decoration: none;

}

.contact-link:active {
    background-color: rgba(9, 160, 206, 0.527);
    border: 2px solid rgba(9, 160, 206, 0.014);

}

.mobile-nav-container {
    position: absolute;
    top: 0%;
    right: 0%;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 45%;
    flex: 1;
    background-color: #EEEEEE;
}

.mobile-nav-menu {
    position: absolute;
    right: 12%;
    top: 25px;
    /* transform: translateY(-50%); */
    font-size: 1.75rem;

}


.mobile-nav-menu-title {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 2rem;

}

.mobile-nav-links {
    display: flex;
    flex-direction: column;
    /* background-color: #EEEEEE; */
    /* margin-top: 40px; */
    /* position: absolute; */
    /* right: 5%; */

}

.mobile-nav-link {
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
    padding: 0.25rem;
    margin-top: 1.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;

}

.mobile-nav-link:hover {
    /* background-color: grey; */
    text-decoration: underline;

    /* color: red; */
}

.mobile-nav-link:active {
    text-decoration: none;
    /* color: rgb(35, 182, 35); */
}