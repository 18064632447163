.additive-demo-container {
    /* background-color: blue; */
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    /* overflow-x: scroll; */
}

.weights-container {
    display: flex;
    margin-top: 3%;
    overflow-x: auto;
    /* padding-left: 70px; */
    /* width: 100%; */
    /* border: 2px solid black; */
    /* width: 800px; */
}

.weights-container::-webkit-scrollbar {
    display: block;
    overflow: auto;
    height: 2em;
    width: 5px;
    height: 8px;
    background-color: #aaa;

}

.weights-container::-webkit-scrollbar-thumb {
    background: #000;
}

.weight-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 20px; */
    flex-direction: column;
    min-width: 30px;
    max-width: 30px;
    font-size: 1rem;
    position: relative;
}

.harmonic-controller-container {
    display: flex;  
    align-items: center;
    justify-content: space-between;
    /* overflow-x: scroll; */
}

.harmonic-number-container {
    display: flex;
    width: 100%;
    height: 60px;
    margin-right: 5%;
    flex-direction: column;
}

.harmonic-presets-container {
    display: flex;
    flex-direction: column;
    height: 60px;
    flex-basis: 50px;
    align-self: flex-start;


}

.harmonic-presets-list {
    display: flex;
    /* margin-top: 1%; */
    

}

.harmonic-preset-title {
    border: 0px 2px solid #EEEEEE;
    margin-bottom: 8px;
}

.harmonic-preset {
    padding: 1% 3%;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    /* box-sizing: border-box; */
    /* margin-right: 2%;  */
}

.harmonic-title {
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-bottom: 8px;
    /* padding: 5px 0px; */
}

.harmonic-input-container {
    /* height: 100%; */
    display: flex;
    align-items: center;
    /* padding: 2px; */
    /* width: 100%; */
    width: 120px;
    /* margin-top: 1%; */
    /* height: 30px; */

}

.harmonic-input {
    background-color: transparent;
    font-size: 1.2rem;
    color: #616161;
    flex: 1;
    /* width: default!important; */
    width: 0px;
    text-align: center;
    padding: 1px 2px!important;
    font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
    border-top: 1px solid #616161;
    border-bottom: 1px solid #616161;
    border-left: 0px;
    border-right: 0px;
    height: 23px;
    padding: 3%;
}

.harmonic-delete-add {
    font-size: 14px;
    cursor: pointer;
    /* margin-top: 6%; */
    padding: 3%;
    /* margin: 2%;     */
    border: 1px solid #616161;
    height: 17px;
    color:rgb(9, 160, 206);
    padding: 1px 2px!important;
    height: 23px;

    /* width: 25px!important; */

}

.harmonic-input::-webkit-outer-spin-button,
.harmonic-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.weight-container:hover .weight-delete {
    display: block;
}

.weight-delete {
    display: none;
    position: absolute;
    top: 0%;
    right: 10%;
    color: #EA5D5D;
    cursor: pointer;
}

.weight-delete:hover {
    color: red;
}

.add-weight {
    align-self: center;
    margin: 2%;
    color: #616161;
    font-size: 30px;
    cursor: pointer;
}


.weight-label {
    /* margin-right: 2%; */
    
}

.weight-slider-wrapper{
  display: inline-block;
  width: 20px;
  height: 150px;
  padding: 0;
}

.weight-slider {
    /* flex: 1; */
    width: 140px;
    margin: 0px;
    /* height: 150px; */
    stroke: red;
    fill: purple;
    background-color: transparent;
    /* background: red!important; */
    /* transform: rotate(-90deg); */
    /* -webkit-appearance: slider-vertical; */
    outline: none;
    transform-origin: 75px 75px;
    transform: rotate(-90deg);
    border: 1.5px solid #616161;
    /* height: 20px; */
    position: absolute;
}


.weight-slider {
    -webkit-appearance: none;
    /* Hides the slider so that custom slider can be made */
    /* Specific width is required for Firefox. */
    background: transparent;
    /* Otherwise white in Chrome */
}

.weight-slider:focus {
    outline: none;
    /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.weight-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid rgb(9, 160, 206);
    height: calc(100% + 0px);
    width: 4px;
    background: #ffffff;
    cursor: pointer;
    background-color: rgb(9, 160, 206);

}

.weight-slider::-webkit-slider-runnable-track {
    height: 20px;
    width: 150px;
    cursor: pointer;
}