.synthesis-page {
    background-color: #293038;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    width: 100vw;
    height: 90vh; 
    overflow-y: scroll;

}

.synthesis-menu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* background-color: #616161; */
    background-color: #293038;
    /* border: 2px solid black; */
    /* border-radius: 8px; */
    /* width: 100%; */
    z-index: 10;
    position: absolute;
    width: 70px;
    height: 100%;
    /* left: -200px; */
    
}

@keyframes hover-width {
  from {width: 250px;}
  to {width: 20%;}
}

.synthesis-menu:hover {
    width: 20%;
    /* animation: hover-width 0.5s ease-in; */
}

.synthesis-menu-item {
    /* margin-left: 1rem; */
    padding: 1rem 1.5rem;
    /* font-size: 14px; */
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 15;
    display: flex;
    min-height: 30px;
}

.synthesis-menu-item:hover {
    background-color: #616161;
}

.synthesis-menu-arrow {
position: absolute;
right: 0px;
top: 50%;
transform: translate(0%, -50%);
border-left: 0px solid transparent;
border-right: 12px solid white;
border-top: 12px solid transparent;
border-bottom: 12px solid transparent;
background-color: transparent;
width: 0px;
height: 0px;
display: none;
overflow-y: scroll;
z-index: 25;
}

.synthesis-menu-item-active {
    /* font-weight: bold; */
    background-color: #616161;
}

.synthesis-menu-item-text {
    flex: 1;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 1rem;
}

.synthesis-menu-item-text:hover {
    /* color: white!important; */
    /* background-color: #616161; */
}

.synthesis-menu-item-icon {
    stroke: white;
    fill: white;
}

.synthesis-content {
    box-sizing: border-box;
    background-color: rgb(238, 238, 238);
    color: #616161;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 70px);
    /* flex: 4; */
    overflow-y: scroll;
    height: 100%;
    /* height: 90vh; */
    position: absolute;
    left: 70px;

}

.synthesis-content-title {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-size: 4rem;
    font-weight: bold;

}

.synthesis-content-text {
    display: block;
    width: 50%;
    padding: 0.5rem;
    font-size: 1.3rem;
    line-height: 1.8;
}

@media (max-width: 1000px) {
    .synthesis-content-text {
        width: 80%;
    }
}

.synthesis-example-container {
    display: flex;
    flex-direction: column;
    border-radius: 22px;
    border: 2px solid grey;
    justify-content: center;
    padding: 1rem;
    /* width: 100%; */
    color: black;
    background-color: white;

}
.synthesis-freq-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.synthesis-freq-number-container {
    display: flex;
    align-items: center;
}

.synthesis-preset-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.synthesis-label {
    font-size: 1rem;
    margin: 0.5rem 0;

}

.synthesis-partials-container {

}

.synthesis-partials-values-container {
    margin-top: 0.5rem;
    display: flex;
}



.synthesis-example-analysis-container {
    display: flex;
    /* flex-grow: 0.25; */
    margin-top: 0.5rem;
    justify-content: space-between;
}

#nexus-ui-select-1 {
    /* margin: 0.5rem 0!important; */
}