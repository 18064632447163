.analysis-graph-container {
    /* flex: 3; */
    background-color: transparent;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    flex: 1;
    height: 333px;
    font-size: 1.2rem;
}

.waveform-canvas {
    position: absolute;
    z-index: 1;
}

.frequency-canvas {
    position: absolute;
    z-index: 2;
}
.waveform-title {
    position: absolute;
    left: 0%;
    top: 0%;
    font-size: 0.75rem;
}

.frequency-title {
    position: absolute;
    left: 0%;
    top: 50%;    
    font-size: 0.75rem;

}