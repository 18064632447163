.about-content {
    background-color: #293038;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
}

.about-content i {
    font-size: 0.9em;
}

.about-content-container {
    display: flex;
    align-self: flex-start;
    margin-top: 5%;

}

.about-photo-container {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: keep-all;
}



.about-photo {
    height: 20rem;
    width: 19rem;
    margin-bottom: 1rem;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.9);
}

.about-photo-photo-container {
    position: relative;
}

.about-secret {
    position: absolute;
    z-index: 20;
    color: green;
    left: 46%;
    top: 39%;
    background-color: black;
    padding: 0.5rem 1.25rem;
    opacity: 0;
}

.about-socials-container {
    min-width: 19rem;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
}

.social-icon {
    margin-right: 1.2em;
    height: 30px;
    width: 30px;
}

.about-blurb-container {
    width: 60%;
    line-height: 150%;
    margin-top: 0%;
}

@media (max-width: 1000px) {
    .about-content-container {
        flex-direction: column;
        gap: 12px;
        align-items: center;

    }

    .about-paragraph-container {
        width: 60%;
        display: flex;
        flex-direction: column;
    }

    .about-blurb-container {
        width: 100%;
    }
}


.about-paragraph-container {
    flex: 2.5;
}


ul {
    list-style: none;
    padding-left: 1em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.list-icon {
    flex: 1;
    height: 2em;
    margin-right: 1em
}

.education-list-icon {
    flex: 0.5;
    height: 4.7em;
    width: 4.7em;
    margin-right: 1.25em;
    justify-items: center;
}

.about-list {
    padding: 0.5em 0;
    font-size: 0.9em;

}


.about-list-row {
    display: flex;
    margin: 1rem 0 2rem 0;

}

.about-list-title {
    font-size: 1.5em;
}

.list-text {
    flex: 10;
    display: flex;
    flex-direction: column;
}

.list-text * {
    width: fit-content;
}

.list-text-title {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
}


.about-text-description {
    font-size: 1em;
}

.list-icon {
    height: 2em;
}