.subtractive-demo-container {
    /* background-color: blue; */
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
}

.subtractive-sound-selection-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtractive-sound-selection-input-container {
    display: flex;
    /* width: 100%; */
    min-width: 150px;
    /* height: 60px; */
    /* margin-right: 5%; */
    flex-direction: column;
    align-self: flex-start;

}

.wave-selection-title {
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-bottom: 8px;
}

.wave-selection-input {
    position: relative;
    /* padding: 10px 5px; */
    /* height: 5px; */
    width: 120px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    /* border: 2px solid #616161; */
}

.wave-selection-input-left {
    position: absolute;
    left: 0%;
    top: 20%;
    width: 20px!important;
    /* padding: 4px 0px; */

}

.wave-selection-input-right{
    position: absolute;
    right: 0%;
    top: 20%;
    width: 20px!important;

    /* padding: 4px 0px; */

}

.subtractive-filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    touch-action: none;

}

.subtractive-filter {
    width: 100%;
    height: 150px;
    background-color: rgb(41, 48, 56);
    cursor: grab;
}

.subtractive-filter-presets-container {
    /* position: absolute; */
    /* bottom: 7%; */
    /* left: 5%; */
    display: flex;
    align-self: flex-start;

}

.filter-preset {
    font-size: 0.75rem;
    /* color: white; */
    box-sizing: border-box;
}