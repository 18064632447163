.music-container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: scroll;
    background-color: #293038;
    /* height: 100vh; */
}
.music-title {
    font-size: 2.5rem;  
    margin-top: 1%;
    margin-bottom: 1%;
}

@media (max-width: 1000px) {
    .music-title {
        align-self: flex-start;
        margin-left: 2%;
    }
    .music-hr {
        display: none;
    }
}

.music-content-container {
    display: flex;
    /* flex-direction: column; */
    /* height: 98%; */
    width: 100%;
    margin-top: 2%;

}

.music-production-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    /* justify-content: center; */
}

.music-mixing-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    /* justify-content: center; */

}

.music-other-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    /* align-items: center; */
}

.music-category-title {
    font-size: 1.5rem;
    background-color: black;
    padding: 5px 10px;
 
}

.music-list {
    color: white;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px);
    justify-content: center;
    /* padding: 0px 10px; */
    /* text-decoration: none; */
    
}

.music-list-item {
    /* margin-left: 1rem; */
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.music-list-link {
    text-decoration: none;
    color: white;
}

.music-list-link:hover {
    text-decoration: underline;
}

.music-list-link:active {
    text-decoration: none;
}

.list-icon {
    flex: 1;
    height: 2em;
    margin-right: 1em
}

.music-content-divider {
    margin-block-start: 0;
    border-width: 0 0 0 2px;
    /* height: 50px; */
}

.music-iframe {
    width: 250px;
    height: 110px;
    border: 0;
    margin-top: 1%;;
}
