.demo-container {
    border: 2px solid #616161;
    display: flex;
    flex-direction: column;
    padding: 3%;
    margin: 2%;
    min-width: 250px;
    /* overflow-x: scroll; */
}

.demo-graphs {
    display: flex;
    flex:1;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-top: 3%;
    cursor: pointer;
    /* background-color: blue;; */
}