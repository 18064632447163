.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-container-grey-background {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  height: 100vh;
  background-color: #293038;
}

div {
  outline: none;
  user-select: none;
}

.route-wrapper {
  position: relative;
}

.route-wrapper>div {
  position: absolute;
}

img {
  max-width: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  /* width: 10px; */
  /* background: transparent; */
  display: none;
  /* make scrollbar transparent */
}


.test {
  position: absolute;
  left: 50%;
  top: 0%;
  height: 100vh;
  width: 1%;
  /* background-color: blue; */
  transform: translateX(-50%);

}

.not-found-page {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 100vw;
  height: 90vh;
}

.heart[moveDown='1'] {
  animation: moveDown 5s 1;
}

@keyframes moveDown {
  0% {
    transform: translateY(-10vh);
    opacity: 0.2;
    visibility: visible;
  }

  100% {
    transform: translateY(110vh);
    opacity: 1;
    visibility: visible;
  }
}

.heart {
  width: 30px;
  height: 30px;
  top: 0%;
  position: absolute;
  z-index: 1;
  visibility: hidden;
}

a {
  text-decoration: none;
  color: #2d8ee3;
}