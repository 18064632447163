.home-content {
    /* position: absolute; */
    /* left: 50%; */
    /* top: 25%; */
    /* transform: translateX(-50%); */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;


}

.home-name {
    color: white;
    font-size: 3rem;
    font-weight: 600;
}

.home-subtitle {
    color: white;
    font-style: italic;
}

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 2px solid red; */
    height: 90vh;
    /* background-color: blue; */
    width: 100vw;
    /* background-image: url("/back.webp");
    background-repeat: no-repeat;
    background-size: cover; */
}