.envelopes-demo-container {
    /* background-color: blue; */
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
}

.envelopes-sound-selection-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.envelopes-sound-selection-input-container {
    display: flex;
    /* width: 100%; */
    min-width: 150px;
    /* height: 60px; */
    /* margin-right: 5%; */
    flex-direction: column;
    align-self: flex-start;

}

.wave-selection-title {
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-bottom: 8px;
}

.wave-selection-input {
    position: relative;
    /* padding: 10px 5px; */
    /* height: 5px; */
    width: 120px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    /* border: 2px solid #616161; */
}

.wave-selection-input-left {
    position: absolute;
    left: 0%;
    top: 20%;
    width: 20px !important;
    /* padding: 4px 0px; */

}

.wave-selection-input-right {
    position: absolute;
    right: 0%;
    top: 20%;
    width: 20px !important;

    /* padding: 4px 0px; */

}

.envelopes-graph-container {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    touch-action: none;
}

.envelope-graph-triggers-container{
    position: relative;
    height: 1.5rem;
    
}

.envelopes-graph-note-trigger {
    position: absolute;
    font-size: 1rem;
}

.envelopes-graph {
    width: 100%;
    height: 150px;
    background-color: rgb(41, 48, 56);
    cursor: grab;
    min-width: 500px;
}

.envelope-zone-container {
    position: relative;
    width: 100%;
    /* background-color: black; */
    height: 1.5rem;
    display: flex;
    justify-content: center;

}

.graph-label-container {
    position: absolute;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.sustain-graph-label {
    color: rgba(238, 238, 238, 0.5);
}

.attack-text {
    color: rgb(147, 33, 76);
}

.decay-text {
    color: rgb(109, 234, 166);
}

.sustain-text {
    color: rgb(242, 187, 60);
}

.release-text {
    color: rgb(132, 61, 183);
}