.projects-content {
    display: flex;
    /* grid-template-rows: 25% 100px auto; */
    background-color: #293038;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100vw;
    /* background-image: url("/background.webp");
    background-repeat: repeat-y;
    background-size: 100vw 100vh;    */
}

.projects-container {
    display: grid;
    /* grid-auto-columns: repeat(auto-fill, 500px); */
    grid-template-columns: repeat(auto-fill, 500px);
    /* background-color: blue; */
    gap: 40px 20px;
    width: 80%;
    /* max-width: 1200px; */
    margin: 3% 0%;
    justify-content: space-around;
    /* margin: 10%; */
    /* padding: 0% 5%; */
}

.other {
    grid-template-columns: repeat(auto-fill, 300px);
}

.other .project-img {
    height: 12rem;
}

@media (max-width: 1000px) {
    .projects-container {
        grid-template-columns: repeat(auto-fill, 320px);
    }
}

.projects-title {
    font-size: 2.5rem;
    margin-top: 1%;
    margin-bottom: 1%;

}

.project-container {
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    /* background-color: aliceblue; */
    /* background-color: #01142E; */
    position: relative;
    /* overflow-x: scroll; */

    /* padding: 5%; */
}

.project-container:hover {
    /* box-shadow: -5px 0px 4px -7px rgba(0, 0.0, 0.16),
  5px 0px 4px -7px rgba(0, 0.0, 0.23),
  5px 5px 10px 5px rgba(0, 0, 0, 0.35); */

}

.project-img {
    height: 16rem;
    z-index: 5;
    cursor: pointer;
    width: 100%;
    border: 2px solid transparent;


}

.project-img:hover {
    /* box-shadow: -5px 0px 4px -4px rgb(255, 255, 255),
    5px 0px 4px -4px rgb(250, 250, 250),
    5px 5px 10px 5px rgba(184, 184, 184, 0.35); */
    border: 2px solid white;
}

.project-text {
    padding: 2% 0%;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* border: 2px solid grey; */
    /* border-top: 0px; */

}

.project-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.project-description {
    font-size: 0.9rem;
    flex: 1;
}

.project-date-and-github {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-date {
    font-size: 1rem;
    font-style: italic;
}

.project-github {
    height: 20px;
    width: 20px;
    z-index: 10;
}



.ltw-link {
    text-decoration: underline;
    color: white;
    font-size: 0.9rem;
    /* padding: 1rem 0.25rem; */
    cursor: pointer;

}

.ltw-link:hover {
    /* background-color: grey; */
    text-decoration: underline;
    /* color: red; */
}

.ltw-link:active {
    text-decoration: none;
    /* color: rgb(35, 182, 35); */
}